<template>
  <main class="home">
    <h1>Home</h1>
    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam
      voluptates, quod, quia, voluptate quae quidem voluptatem quos voluptatum
      quibusdam quas quia voluptate quae quidem voluptatem quos voluptatum
      quibusdam quas
    </p>
  </main>
</template>

<script>
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {},
};
</script>
